<template>
  <div class="wrapper">
    <div class="advert"></div>
    <div class="top-border"></div>
    <div class="list">
      <div
        class="item"
        @click="selectItem(item)"
        v-for="(item, index) in honeyList"
        :key="index"
      >
        <div class="item-bg">
          <div class="cover">
            <img :src="item.resources_cover" class="cover-img"/>
          </div>
          <div class="info">
            <div class="info-title">
              <div class="title">{{ item.resources_name }}</div>
              <div class="player"></div>
            </div>
            <div class="desc">
              {{ item.resources_desc }}
            </div>
            <div class="author-block">
              <div class="author">作者：{{ item.resources_author }}</div>
              <!--              <div class="anchor">主播：男丁</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'

export default {
  name: 'HoneyList',
  props: {
    honeyList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters([
      'userGuid'
    ])
  },
  methods: {
    selectItem (item) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getAudios({
        resources_guid: item.resources_guid,
        c_user_guid: this.userGuid
      }).then(res => {
        if (res.code === 200) {
          if (!res.data.list.length) {
            Toast.fail('暂无音频数据')
            return false
          }
          this.selectPlay({
            list: res.data.list,
            index: res.data.current_index
          })
        } else if (res.code === 401) {
          appInit(this.agencyGuid, this.$route.path)
        } else if (res.code === 402) {
          this.$router.push({
            name: 'Login',
            params: {
              link: this.$route.path
            }
          })
        } else {
          Toast.fail(res.msg)
        }
        Toast.clear()
      })
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%

  .advert
    width 690px
    height 203px
    margin 0 auto
    bg-image('~@/assets/img/advert')
    background-size 100% 100%
    background-repeat no-repeat

  .top-border
    width 690px
    height 62px
    margin 0 auto
    bg-image('~@/assets/img/honey/top-boder')
    background-size 100% 100%
    background-repeat no-repeat

  .list
    width 690px
    margin 0 auto

    .item
      display table
      width 690px
      height 268px
      margin-bottom 10px
      bg-image('~@/assets/img/honey/bg')
      background-size 100% 100%
      background-repeat no-repeat

      .item-bg
        display flex
        align-items center
        width 674px
        height 252px
        border-radius 10px
        margin 8px auto
        background #fff

        .cover
          width 166px
          height 228px
          margin 0 17px 0 10px
          border-radius 10px
          overflow hidden

          .cover-img
            width 100%
            height 100%

        .info
          width 480px
          height 228px

          .info-title
            display flex
            width 480px
            height 52px
            margin-top 5px

            .title
              width 400px
              height 52px
              line-height 52px
              font-size 26px
              font-weight 500
              color rgba(51, 51, 51, 1)
              no-wrap()

            .player
              width 52px
              height 52px
              bg-image('~@/assets/img/honey/player')
              background-size 100% 100%
              background-repeat no-repeat

          .desc
            width 452px
            height 60px
            margin-top 30px
            line-height 32px
            font-size 24px
            color rgba(153, 153, 153, 1)
            no-wrap2(2)

          .author-block
            display flex
            width 480px
            height 28px
            margin-top 42px
            line-height 28px
            font-size 22px
            color rgba(153, 153, 153, 1)

            .author
              width 262px
              height 28px
              //border-right 1px solid rgba(153, 153, 153, 1)
              no-wrap()

            .anchor
              width 196px
              height 28px
              margin-left 20px
              no-wrap()
</style>
