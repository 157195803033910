<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="honey">
      <honey-header></honey-header>
      <scroll
        ref="scroll"
        class="scroll"
        :data="honeyList"
        :listen-scroll="true"
        @scroll="scroll"
        :pull-down-refresh="{threshold: 30, stop: 0}"
        @pullingDown="pullDownRefresh"
        :pull-up-load="true"
        @pullingUp="pullUpPush"
      >
        <div>
          <div class="pull-down-wrapper">
            <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
            <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
          </div>
          <honey-list :honey-list="honeyList"></honey-list>
          <div class="home-bottom" v-show="showDivider">
            <divider class="divider">已加载全部内容</divider>
          </div>
        </div>
      </scroll>
      <loading class="loading-container" v-show="showLoading">加载中...</loading>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import HoneyHeader from './components/Header'
import HoneyList from './components/List'
import { Divider, Loading } from 'vant'
import { getHoneyList } from '@/api/Honey'

export default {
  name: 'Honey',
  components: {
    Scroll,
    HoneyHeader,
    HoneyList,
    Loading,
    Divider
  },
  mounted () {
    this._honeyInit()
  },
  data () {
    return {
      page: 1,
      limit: 10,
      showLoading: false,
      showDivider: false,
      isPullingDown: false,
      honeyList: []
    }
  },
  methods: {
    _honeyInit () {
      this._getHoneyList()
    },
    _getHoneyList () {
      this.showLoading = true
      getHoneyList({
        page: this.page,
        limit: this.limit
      }).then(res => {
        if (res.code === 200) {
          this.honeyList = this.honeyList.concat(res.data.list)
          if (res.data.count <= this.honeyList.length) {
            this.showDivider = true
          }
        }
        this.showLoading = false
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.honeyList.splice(0, this.honeyList.length)
      this.showDivider = false
      this._getHoneyList()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getHoneyList()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style lang="stylus" scoped>
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.honey
  position absolute
  z-index 10
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 74px
    left 0
    right 0
    bottom 0
    bottom constant(safe-area-inset-bottom)
    bottom env(safe-area-inset-bottom)
    overflow hidden
    .pull-down-wrapper
      display flex
      justify-content center
      align-items center
      width 750px
      height 70px
      margin-top -70px

      .pull-down-text
        line-height normal
        font-size 22px
        color rgba(153, 153, 153, 1)
    .home-bottom
      wdith 750px
      height 70px
      padding-top 30px

      .divider
        width 300px
        height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin 0 auto
        border-color rgba(153, 153, 153, 1)

  .loading-container
    position absolute
    width 100%
    top 50%
    transform translateY(-50%)
    text-align center
</style>
